// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import '../vendors/materialize/sass/materialize';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;
    // to fix parallax bug on iphone...
    margin-top: $size-nav-height;
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
    @media screen and (max-width: $size-xs-max) {
          margin-top: $size-nav-height-mobile;
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;
    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
//@import 'modules/module-slider-light';
//@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/

div.container-fluid.row {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1920px;
    margin: auto;
    @media screen and (max-width: $size-xs-max){
        & > div {
          width: 100%;
          transition: .3s;
        }
    }
}

/*****************************************************/
/* INDEX.PHP
/*****************************************************/
section.header-accueil {
    margin: 50px 0 100px;;
    div.left-side {
        width: 35%;
    }
    div.right-side {
        width: 65%;
        position: relative;
        div.carousel-wrapper {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 70%;
            z-index: 333;
            max-width: 600px;
            padding: 40px 0 0 40px;
            background-color: $color-white;
        }

        .owl-nav {
            position: absolute;
            bottom: -60px;
            display: flex;
            div.owl-prev, div.owl-next {
                margin-right: 15px;
                transition: .3s;
                &:hover {
                    opacity: .8;
                    transition: .3s;
                }
            }
        }
    }
    h3 {
        font-size: $font-size-30;
        color: $color-text-base;
        font-weight: 400;
        line-height: 1.2;
        text-transform: uppercase;
        span {
            font-weight: bold;
        }
    }
    @media screen and (max-width: 1100px){
        h3 {
          font-size: 2vw;
        }
    }
    @media screen and (max-width: $size-sm-max){
      div.left-side {
          .rbq.pb14 {
              width: 70%;
              padding-bottom: 50px;
          }
      }
        @media screen and (max-width: $size-xs-max){
            h3 {
              font-size: 24px;            }
            div.left-side, div.right-side {
                width: 100%;
            }
            div.right-side{
                order: -1;
                margin-bottom: 100px;
                div.carousel-wrapper {
                    position: absolute;
                    right: 20px;
                    //left: 20px;
                    bottom: 0;
                    z-index: 333;
                  //  width: 100%;
                    padding: 20px 20px 0 20px;
                }
            }
            @media screen and (max-width: 400px){
              h3 {
                font-size: 20px;
              }
                div.right-side{
                    order: -1;
                    div.carousel-wrapper {
                        position: absolute;
                        right: 20px;
                        left: 20px;
                        width: auto;
                        padding: 10px 10px 0 10px;
                    }
                }
            }
        }
    }
}

div.btn-contact {
    max-width: 200px;
    .phone-wrapper {
        margin-top: 30px;
        .phonenumber {
            font-size: 18px;
            padding-left: 15px;
        }
    }
}


section.section-text-image {
    div.container-fluid.row {
        align-items: stretch;
    }
    div.left-side, div.right-side {
        width: 50%;
    }
    .px2 {
      height: 100%;
    }
    p {
        font-weight: 400;
        color: #777777;
        font-size: $font-size-18;
        line-height: 1.2;
        &.small {
          font-size: $font-size-16;
        }
    }
    h3 {
        font-size: $font-size-50;
        color: $color-text-base;
        line-height: 1.2;
    }
    h4 {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.2;
        text-transform: uppercase;
    }

    div.desc {
        width: 30%;
    }
    div.villes-wrapper {
        width: 70%;
    }
    div.ville {
        width: 15%;
        padding: 5px;
        min-width: 100px;
    }
    @media screen and (max-width: $size-md-max){
        div.desc , div.villes-wrapper {
            width: 100%;
        }
        @media screen and (max-width: $size-xs-max){
            div.left-side , div.right-side {
                width: 100%;
                padding: 20px 0;
            }

            p {
                font-size: 16px;
                &.small {
                  font-size: 15px;
                }
            }
            h3 {
                padding: 20px 0;
                font-size: 24px;
            }
        }
    }
}

.section-carousel-realisations {
    margin-bottom: 100px;
    .owl-nav {
        position: absolute;
        bottom: -60px;
        display: flex;
        div.owl-prev, div.owl-next {
            margin-right: 15px;
            transition: .3s;
            &:hover {
                opacity: .8;
                transition: .3s;
            }
        }
    }
}

section.section-img-ronde {
      position: relative;
      margin: 50px 0;
      padding-bottom: 50px;
      div.background {
          z-index: -1;
          top: 50%;
          left: 5.5%;
          right: 70%;
          height: 50%;
          // bottom: 0;
          width: 75%;
          position: absolute;
          background-color: $color-primary;
          display: flex;
          align-items: center;
          padding-left: 3%;
          h3 {
            font-weight: bold;
            font-size: $font-size-46;
            color: #F9E7E3;
          }
          @media screen and (max-width: 1225px){
            position: initial;
            height: initial;
            padding: 50px;
            @media screen and (max-width: $size-xs-max){
              padding: 0;
              width: 100%;
              h3 {
                text-align: center;
              }
            }
          }
      }
      div.container-fluid.row {
          align-items: flex-start;
      }
      div.left-side , div.right-side {
          width: 50%;
      }

      h3 {
          padding-top: 50px;
          text-transform: inherit;
          font-size: $font-size-40;
          color: $color-text-base;
          line-height: 1.2;
      }
      @media screen and (max-width: $size-xs-max){
          background-color: $color-primary;
          div.left-side, div.right-side {
              width: 100%;
          }
          div.right-side {
            padding-top: 35px;
          }
          h3 {
            font-size: 20px;
            color: $color-white;
          }
      }
}

section.header-drain {
  @extend .header-accueil;

  div.left-side {
      width: 40%;
      @media screen and (max-width: $size-sm-max){
        padding-right: 10px;
        @media screen and (max-width: $size-xs-max){
          padding-right: 0;
        }
      }
  }
  div.right-side {
      width: 60%;
  }
  h3.title-pages {
    font-size: $font-size-40;
  }

  @media screen and (max-width: $size-xs-max){
      div.left-side {
          width: 100%;
      }
      div.right-side {
          width: 100%;
          order: 1;
      }
      h3, p,h4.orange {
        padding-left: 25px;
      }
      .btn-contact {
        margin-left: 25px;
      }
  }
  .mosaique-drain {
      display: grid;
      grid-template-columns: 1fr .74fr;
      grid-gap: 15px;
      align-items: start;
      justify-items: center;
      margin: auto;
      .item {
          box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.3);
          width: 100%;
      }

      .item:nth-child(2) {
          grid-row: span 2;
      }
  }

}

section.section02-drain {
  .text-image {
      padding-top: 50px;
      .left-side {
          width: 35%;
      }
      .right-side {
          margin-left: 5%;
      }
      .section-text {
          padding-top: 25px;
          h4 {
              font-weight: bold;
              font-size: $font-size-30;
              color: #CF4B2D;
              text-transform: uppercase;
              padding-bottom: 10px;
          }
          p {
              line-height: 1.5;
          }
          .text {
              padding-right: 5%;
          }
      }
      @media screen and (max-width: 1150px){
        flex-direction: column;
        .left-side {
            width: 40%;
            padding-bottom: 35px;
        }
        .right-side {
            margin-left: 0;
        }
        @media screen and (max-width: $size-sm-max){
          .left-side {
              width: 70%;
          }
          @media screen and (max-width: $size-xs-max){
            .left-side {
                width: 100%;
            }
            .right-side {
              .section-text {
                  flex-direction: column;
                  .logo {
                      width: 50%;
                  }
              }
            }
          }
        }
      }
  }
}

#section-burst {
    padding: 25px 6% 75px 6%;
    .border {
        height: 1px;
        background: #494949;
        margin-bottom: 25px;
    }
    a {
      font-weight: 900;
      font-size: $font-size-20;
      color: #494949;
      transition: 0.3s;
      &:hover {
          color: #CF4B2D;
          transition: 0.3s;
      }
    }
}

#section-image-text {
    padding: 25px 5% 100px 0;
    .left-side {
        margin-right: 5%;
    }
    .right-side {
      h4 {
          font-weight: bold;
          font-size: $font-size-30;
          color: #CF4B2D;
          text-transform: uppercase;
          padding-bottom: 10px;
      }
      p {
          line-height: 1.5;
      }
    }
    @media screen and (max-width: 1030px){
      .right-side p br {
        display: none;
      }
      @media screen and (max-width: $size-xs-max){
        flex-direction: column;
        padding: 25px 5% 75px 5%;
        .left-side {
            margin-right: 0;
            padding-bottom: 35px;
        }
      }
    }
}


section.section-img-header {
    @media screen and (max-width: $size-xs-max){
        flex-wrap: wrap;
        .item {
            width: 50%;
            padding: 5px;
            margin: auto;
        }
    }
}

section.section02-fissures {
    background-color: $color-primary;
    padding: 100px 0 50px 0;

    h3 {
      color: #DC7C66;
      text-align: right;
    }
    p {
        color: #FDFDFD;
    }
    div.btn-contact .phone-wrapper {
      margin-top: 0;
      margin-left: 30px;
      .phonenumber {
        color: #C1C1C1;
        transition: .3s;
      }
      &:hover {
        .phonenumber {
          transition: .3s;
          color: #fff;
        }
      }
    }
    @media screen and (max-width: $size-xs-max){
      h3 {
        color: #DC7C66;
        text-align: left;
      }
    }
}


section.section03-fissures  {
  padding: 50px 5%;
  .section-title.flex {
      padding-bottom: 50px;
      .left-side {
        width: 25%;
        h3 {
            font-weight: normal;
            font-size: $font-size-30;
            color: #494949;
        }
      }
      .right-side {
        h2 {
            font-weight: 600;
            font-size: $font-size-32;
            color: #494949;
            line-height: 1.5;
        }
      }
      @media screen and (max-width: 1455px){
        .left-side {
          width: 35%;
        }
        @media screen and (max-width: $size-sm-max){
          flex-direction: column;
          .left-side {
            width: 100%;
            padding-bottom: 15px;
          }
          .right-side {
            h2 br {
              display: none
            }
          }
        }
      }
  }
  .section-text {
      h4 {
          font-weight: 600;
          font-size: $font-size-32;
          color: #494949;
          padding-bottom: 15px;
      }
      p {
        font-size: $font-size-18;
        line-height: 1.5;
      }
  }
}

#section-title-img {
    padding: 0 5%;
    h2 {
        font-weight: bold;
        font-size: $font-size-30;
        color: #494949;
        text-transform: uppercase;
        padding-bottom: 20px;
    }
    @media screen and (max-width: $size-xs-max){
      .section-images {
        flex-direction: column;
        .left-side.mr2 {
            margin: 0 0 15px;
        }
      }
    }
}

#section-text-img {
    padding: 75px 0;
    .left-side {
      width: 57%;
      margin-right: 50px;
  }
  h3 {
      font-weight: bold;
      font-size: $font-size-40;
      color: #494949;
  }
  @media screen and (max-width: $size-sm-max){
    padding: 75px 5%;
    flex-direction: column-reverse;
    .left-side {
        width: 80%;
        margin-right: 0;
    }
    .right-side {
        text-align: center;
        margin-bottom: 15px;
    }
    @media screen and (max-width: $size-xs-max){
      .left-side {
          width: 100%;
      }
    }
  }
}

#section-img-text {
    padding: 50px 0 75px 5%;
    .left-side {
        width: 40%;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: #494949;
            padding-bottom: 20px;
        }
        p {
          line-height: 1.5;
        }
    }
    .right-side {
      width: 57%;
      margin-left: 50px;
  }
  @media screen and (max-width: $size-sm-max){
    flex-direction: column;
    padding: 50px 5% 75px 5%;
    .left-side {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }
    .right-side {
        width: 80%;
        margin-left: 0;
    }
    @media screen and (max-width: $size-xs-max){
        .right-side {
          width: 100%;
        }
     }
  }
}

#section-video {
    padding: 50px 5% 75px 5%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1500px){
      iframe {
          width: 100%;
      }
      @media screen and (max-width: 1400px){
        iframe {
            height: 650px;
        }
        @media screen and (max-width: 1024px){
          iframe {
              height: 505px;
          }
          @media screen and (max-width: $size-sm-max){
            iframe {
                height: 385px;
            }
          }
        }
      }
    }
}

section.section01-impermeab {

    div.container-fluid.row {
        align-items: flex-start;
        div.text-wrapper {
            padding: 40px 20px;
            h4 {
                font-size: $font-size-24;
            }
            &.orange {
                background-color: $color-primary;
                transform: scaleY(1.1);
                z-index: 3333;
                p {
                    font-size: 16px;;
                    color: $color-white;
                }
            }
        }
    }
    @media screen and (max-width: $size-md-max){
      div.container-fluid.row {
          align-items: flex-start;
          div.text-wrapper {
              padding: 40px 20px;
              h3 {
                  font-size: 26px;
              }
            }
        }
        @media screen and (max-width: $size-sm-max){
          div.container-fluid.row {
              div.text-wrapper {
                  padding: 20px 10px;
                    h3 {
                        font-size: 26px;
                    }
                }
            }
        }
    }
}

.section03-drain {
  margin: 100px 0;
    div.left-side {
        width: 50%;
        position: relative;
        div.caption {
            padding: 30px;
            min-height: 150px;
            transform: translateX(100px);
            border-radius: 10px;
            background-color: $color-white;
            h4 {
                font-weight: bold;
                font-size: $font-size-28;
            }
            p {
                padding-left: 20px;
            }
            .logo {
              max-width: 100px;
              margin-left: auto;
            }
        }
    }
    div.right-side {
        width: 50%;
    }
    @media screen and (max-width: $size-xs-max){
        div.right-side, div.left-side{
            width: 100%;
            div.caption {
                transform: translateX(0);
              }
        }
    }
}

section.section02-impermeab {
    @extend .section03-drain ;
    div.left-side div.caption {
      background-color: $color-primary;
      h4 {
          color: #DC7C66;
          font-size: $font-size-50;
          font-weight: 400;
      }
      p {
          color: #F9E7E3;
          padding-left: 0;
      }

    }
    @media screen and (max-width: $size-xs-max){
        div.left-side {
            order: 1;
        }
      }
}

section.section-video {
  .section-text {
      padding-bottom: 25px;
      h4 {
          font-weight: bold;
          font-size: $font-size-30;
          color: #CF4B2D;
          text-transform: uppercase;
          padding-bottom: 10px;
      }
      p {
          line-height: 1.5;
      }
      .text {
          padding-right: 5%;
      }
      @media screen and (max-width: $size-xs-max){
        flex-direction: column;
        text-align: center;
        .logo {
            width: 50%;
        }
      }
  }
}

.section04-drain {
    @extend .section03-drain ;
    div.left-side {
        div.caption {
            transform: translateX(-100px);
          }
      }
      @media screen and (max-width: $size-xs-max){
          div.right-side, div.left-side{
              width: 100%;
              div.caption {
                  transform: translateX(0);
                }
          }
      }
}

section.section03-impermeab {
    @extend .section04-drain;
    div.left-side div.caption {
        background-color: $color-primary;
        h4 {
            color: #DC7C66;
            font-size: $font-size-50;
            font-weight: 400;
        }
        p {
            color: #F9E7E3;
            padding-left: 0;
        }
    }
    @media screen and (max-width: $size-xs-max){
        div.left-side {
            order: 1;
        }
    }
}

section.section-video {
    div.container-fluid.row {
        justify-content: flex-start;
    }
    h3 {
      font-size: $font-size-60;
      text-transform: inherit;
      margin-bottom: 30px;
    }
    iframe {
        width: 100%;
        height: calc(100vw * 625 / 1111)
    }
}

.section04-impermeab {
    div.container-fluid.row {
      align-items: stretch;
    }
    div.left-side, div.right-side {
        width: 50%;
    }
    div.right-side {
        background-color: $color-primary;
        display: flex;
        align-items: center;
        h3 {
          color: $color-white;
          font-size: $font-size-30;
        }
    }
    @media screen and (max-width: $size-xs-max){
        div.right-side {
            h3 {
                font-size: 20px;
            }
        }
    }
}


.materialcarousel {
    margin-bottom: 50px;;
    #carousel-prev, #carousel-next {
            top: 50%;
            cursor: pointer;
            position: absolute;
            transform: translateY(-50%);
            z-index: 3;
            font-size: 35px;
            transition: .3s;
    }

  #carousel-next {
        left: 10%;
        img:hover {
              transition: .3s;
              opacity: .7;
        }
  }
  #carousel-prev {
        right: 10%;
        img:hover {
              transition: .3s;
              opacity: .7;
        }
  }
    &.carousel-slider {
        top: 0;
        left: 0;

        .carousel-fixed-item {
            &.with-indicators {
                bottom: 68px;
            }

            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
            z-index: 1;
        }

        .carousel-item {
            width: 100%;
            height: 100%;
            min-height: 400px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .indicators {
        .indicator-item {
            //max-width: 5px;
            margin: 0 20px;
            background-color: $color-primary;
            width: 10px;
            height: 10px;;
            &.active {
                    background-color: $color-black;
            }
        }
    }
    @media screen and (max-width: $size-xs-max) {
        height: 80vw!important;
        @media screen and (max-width: 400px) {
            .carousel-item {
                transform: translate(0)!important;
                max-width: 100%!important;
                width: 100%!important;
            }
        }
    }
    overflow: hidden;
    position: relative;
    width: 100%;
    //perspective: 800px;
    //height: 35vw;
    //transform-style: preserve-3d;
    //transform-origin: 0% 50%;

    .carousel-item {
        visibility: hidden;
        width: 100%;
        max-width: 42%;
        min-height: 426px;
        position: absolute;
        top: 0;
        left: 0;

        & > img {
          width: 100%;
        }
    }
}


section.section02-coordonnees {
        max-width: 90%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
        align-items: start;
        justify-items: center;
        margin: auto;
        .rbq {
            width: 70%;
        }
        .logo-reseau {
          .icon {
              a .hoverable {
                @extend .filter-hover;
              }
              a:hover .hoverable {
                  @extend .filter-normal;
              }
          }
        }
    @media screen and (max-width: $size-sm-max){
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: $size-xs-max){
            grid-template-columns: 1fr;
        }
    }
}

.section-form {
  padding-top: 75px;
  .title {
      padding: 75px 5% 0 5%;
      text-align: center;
      h4 {
        font-size: $font-size-26;
        color: #494949;
        font-weight: 400;
        line-height: 1.3;
      }
  }
}
