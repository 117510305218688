.btn {
    background-color: $color-primary;
    color: $color-white;
    border: 3px solid lighten($color-primary, 20%);
    border-radius: $round-corner-radius;
    padding: 15px;
    font-size: $font-size-16;
    width: 100%;
    transition: all 0.2s ease-in;
    span.btn-em {
        font-size: $font-size-20;
        text-transform: uppercase;
        font-weight: 600;
    }
    &:hover {
        transition: all 0.2s ease-in;
        font-size: $font-size-16;
        background-color: lighten($color-primary, 40%);
        color: $color-black;
        border: 3px solid darken($color-primary, 40%);
    }
}

.btn-overlay {
    position: relative;
    background-color: $color-white;
    border: 1px solid $color-primary;
    height: 50px;
    cursor: pointer;
    width: 100%;
    max-width: 260px;
    transition: all 0.2s ease-in;
    div.txt {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      //z-index: -1;
      bottom: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
        h4 {
            color: $color-white;
            text-transform: uppercase;
            font-size: $font-size-16;
            transition: all 0.2s ease-in;
        }
    }
    div.overlay {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        //z-index: -1;
        bottom: -1px;
        overflow: hidden;
        background-color: $color-primary;
        transition: all 0.5s ease-in;
    }
    &:hover {
        div.overlay {
          left: 100%;
          transition: all 0.5s ease-in;
        }
        transition: all 0.2s ease-in;
        font-size: $font-size-16;
        div.txt {
            h4 {
              transition: all 0.2s ease-in;
              color: $color-text-base;
              text-transform: uppercase;
            }
        }
    }
}
